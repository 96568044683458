import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button } from "gatsby-material-ui-components";
import Img404 from "./404.png";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    textAlign: "center",
    height: "100vh",
  },
  img: {
    height: "calc(100vh - 400px)",
    "@media (max-width:600px)": {
      height: "auto",
      width: "80%",
    },
  },
}));

const Fr404 = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justify="center"
      alignItems="center"
      spacing={4}
    >
      <Grid item>
        <img src={Img404} alt="Not found" className={classes.img} />
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          style={{
            fontFamily: "'Inter', sans-serif",
            padding: matches ? 8 : 0,
          }}
        >
          {
            "The page you requested cannot be found. Don't worry, this is not a dead end."
          }
        </Typography>
      </Grid>
      <Grid
        item
        container
        direction={matches ? "column" : "row"}
        justify="center"
        spacing={matches ? 2 : 4}
      >
        <Grid item>
          <Button
            variant="contained"
            to="/fr/"
            size={matches ? "medium" : "large"}
            color="primary"
          >
            Homepage
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            to="/fr/contact"
            size={matches ? "medium" : "large"}
            color="primary"
            style={{ fontFamily: "'Inter', sans-serif" }}
          >
            Contact us
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Fr404;
